export default  (state = null, action) =>{
    if(action.type === 'FETCH_ROW_DATA'){
        let reducedData = action.payload.reduce((acc, obj)=>{
            const found = acc.some(el=>el.salesChannelName === obj.salesChannelName);
            if(!found){
                acc.push({salesChannelName: obj.salesChannelName, sales : obj.sales})
            }else{
                const objIndex = acc.findIndex(el => el.salesChannelName === obj.salesChannelName);
                for(var key in obj.sales){
                    if(acc[objIndex].sales.hasOwnProperty(key)){
                        acc[objIndex].sales[key] += obj.sales[key];
                    }else{
                        acc[objIndex].sales[key] = obj.sales[key];
                    }
                }
            }

            return acc;
        },[]);
        reducedData.forEach(item => {
            item.sales.total = Object.values(item.sales).reduce((a, b) => a + ( isNaN(b) ? 0 : b ) );
        })

        return reducedData;
    }else if(action.type === "NULL_ROW_DATA"){
        return null;
    }
    else{
        return state;
    }
}