// You can customize the theme with the help of this file

//Template config options
const themeConfig = {
  layout: "horizontal",       // options[String]: "vertical"(default), "horizontal"
  theme: "light",             // options[String]: 'light'(default), 'dark', 'semi-dark'
  sidebarCollapsed: false,    // options[Boolean]: true, false(default)
  navbarColor: "default",     // options[String]: default / primary / success / danger / info / warning / dark
  navbarType: "floating",     // options[String]: floating(default) / static / sticky / hidden
  footerType: "hidden",       // options[String]: static(default) / sticky / hidden
  disableCustomizer: true,    // options[Boolean]: true, false(default)
  hideScrollToTop: false,     // options[Boolean]: true, false(default)
  menuTheme: "primary",       // options[String]: primary / success / danger / info / warning / dark
  direction: "ltr"            // options[String] : ltr / rtl
}

export default themeConfig
