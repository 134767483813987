import Moment from "moment";
import {extendMoment} from 'moment-range';

export default (state = null, action) => {
    if (action.type === 'UPD_DASHBOARD_DATE_RANGE') {
        const moment = extendMoment(Moment);
        switch (action.payload.dateRangeFilter) {
            case 'ytd':
                const start = moment().startOf('year');
                const end = moment();
                const ytd_dateRange = {
                    dateFrom: start.format("YYYY-MM-DD"),
                    dateTo: end.format("YYYY-MM-DD"),
                    groupBy: moment(end).diff(moment(start), 'weeks', true) < 4 ? "week" : "month"
                };
                return {...state, ...ytd_dateRange };
            case 'this_week':
                const this_week_dateRange = {
                    dateFrom: moment().startOf('isoweek').format("YYYY-MM-DD"),
                    dateTo: moment().format("YYYY-MM-DD"),
                    groupBy: 'day'
                }
                return {...state, ...this_week_dateRange};
            case 'this_month':
                const this_month_dateRange = {
                    dateFrom: moment().startOf('month').format("YYYY-MM-DD"),
                    dateTo: moment().format("YYYY-MM-DD"),
                    groupBy: 'day'
                };
                return {...state, ...this_month_dateRange};
            case 'ltm':
                const ltm_dateRange = {
                    dateFrom: moment().subtract(12, 'month').format("YYYY-MM-DD"),
                    dateTo: moment().format("YYYY-MM-DD"),
                    groupBy: 'month'
                };
                console.log(ltm_dateRange)
                return {...state, ...ltm_dateRange};
            case 'last_year':
                const last_year_dateRange = {
                    dateFrom: moment().startOf('year').subtract(1, 'year').format("YYYY-MM-DD"),
                    dateTo: moment().endOf('year').subtract(1, 'year').format("YYYY-MM-DD"),
                    groupBy: 'month'
                };
                return {...state, ...last_year_dateRange};
            case 'last_thirty_days':
                const last_30_dateRange = {
                    dateFrom: moment().subtract(30, 'day').format("YYYY-MM-DD"),
                    dateTo: moment().format("YYYY-MM-DD"),
                    groupBy: 'day'
                };
                return {...state, ...last_30_dateRange};
            case 'custom':
                const custom_start = moment(action.payload.customDateStart);
                const custom_end = moment(action.payload.customDateEnd);
                const custom_dateRange = {
                    dateFrom: custom_start.format("YYYY-MM-DD"),
                    dateTo: custom_end.format("YYYY-MM-DD"),
                    groupBy: moment(custom_end).diff(moment(custom_start), 'day', true) <= 30 ? 'day' : moment(custom_end).diff(moment(custom_start), 'month', true) < 4 ? 'week' : 'month'
                };
                return {...state, ...custom_dateRange};
            default:
                return state;
        }
    } else if (action.type === "VENDOR_FILTER") {
        console.log(action.payload);
        return {...state, vendorIds: action.payload}
    }  else if (action.type === "CHANNEL_FILTER") {
        console.log(action.payload);
        return {...state, salesChannelIds: action.payload}
    }
    else {
        return state;
    }
}