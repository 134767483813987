import {combineReducers} from "redux";
import ExecutiveSalesDataReducer from "./ExecutiveSalesDataReducer";
import dashboardHeaderReducer from "./dashboardHeaderReducer";
import dashboardDateRangeReducer from "./dashboardDateRangeReducer";
import isDataLoadingReducer from "./isDataLoadingReducer";
import activeComponentReducer from "./activeComponentReducer";
import compareToFilterReducer from "./compareToFilterReducer";
import dateRangeFilterReducer from "./dateRangeFilterReducer";
import compareSalesDataReducer from "./compareSalesDataReducer";
import compareSalesHeadersReducer from "./compareSalesHeadersReducer";
import bestSellersDataReducer from "./bestSellersDataReducer";

const dashboardReducers = combineReducers({
    executiveSalesData : ExecutiveSalesDataReducer,
    compareSalesData : compareSalesDataReducer,
    bestSellersData: bestSellersDataReducer,
    dashboardHeaders : dashboardHeaderReducer,
    compareSalesHeaders : compareSalesHeadersReducer,
    dashboardDateRange : dashboardDateRangeReducer,
    isDataLoading: isDataLoadingReducer,
    activeComponent: activeComponentReducer,
    dateRangeFilter: dateRangeFilterReducer,
    compareTo: compareToFilterReducer
})

export default dashboardReducers;