export default (state = null, action) => {
    switch (action.type) {
        case 'FETCH_COMPARE_SALES_DATA':
            return action.payload;
        case "NULL_ROW_DATA":
            return null;
        default:
            return state;
    }
}
