import React from "react"
import * as Icon from "react-feather"

const headerNavigationConfig = [
    {
        id: "home",
        title: "Product Analysis",
        type: "item",
        icon: <Icon.Columns size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/",
    },
    {
        id: "dashboard",
        title: "Sales Dashboard",
        type: "item",
        icon: <Icon.PieChart size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/dashboard",
    }
]

export default headerNavigationConfig
