import Moment from "moment";
import { extendMoment } from 'moment-range';



export default (state =[], action) => {
    if(action.type === "GET_DASHBOARD_HEADERS"){
        const moment = extendMoment(Moment);
        const start = moment(action.payload.dateFrom);
        //next line might looks strange. But at the moment, moment-range lib has a nasty bug with ranges grouped by month, and this ternary expression is a workaround
        const end =  action.payload.groupBy === 'month' ? moment(action.payload.dateTo).endOf("month") : moment(action.payload.dateTo);
        const range = moment.range(start, end)
        const arrayOfDates = Array.from(range.by(action.payload.groupBy))
        const headers = {headersNames:[], headersKeys: []};
        if(action.payload.groupBy === 'day'){
            return arrayOfDates.reduce((acc, item) =>{
                acc.headersNames.push(item.format("MMM Do"));
                acc.headersKeys.push(item.format("M/D/YYYY"));
                    return acc;
            }, headers);
        }else if(action.payload.groupBy === 'week'){
            return arrayOfDates.reduce((acc, item) =>{
                acc.headersNames.push(item.format("wo gggg"));
                acc.headersKeys.push(item.format("w-gggg"));
                return acc;
            }, headers);
        }else if(action.payload.groupBy === 'month'){
            return arrayOfDates.reduce((acc, item) =>{
                acc.headersNames.push(item.format("MMM YY"));
                acc.headersKeys.push(item.format("MMM-YYYY"));
                return acc;
            }, headers);
        }
        return headers;
    }else{
        return state;
    }
}